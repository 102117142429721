@import "../../../bower_components/bootstrap-sass-official/assets/stylesheets/bootstrap";

h1, h2, h3, h4, h5, h6, body, p, div {
  font-family: 'Roboto', sans-serif !important;
}

#btnCopy {
  display: none;
  margin-bottom: 20px;
}

#btnCode {
  margin-top: 20px;
}

#ctrCode {
  display: none;
  height: 160px;
}

div.topheader {
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #FFFFFF, #e2e2e2);
}

div.subheader {
  background-color: #e2e2e2;
}

div.counterselect {
  background-color: #f5f5f5;
  background-image: linear-gradient(to bottom, #f5f5f5, #D8D8D8);
  padding-bottom: 10px;
}

div.counterstart {
  background-color: #D8D8D8;
  min-height: 100px;
  padding-top: 20px;
}

div.countercode {
  background-color: #D8D8D8;
  min-height: 40px;
  padding-top: 10px;
}

div.countertext {
  background-color: #D8D8D8;
  min-height: 300px;
}

div.stats {
  background-color: #e2e2e2;
}

input[type='radio'], label{
  vertical-align: baseline;
  padding: 10px;
  margin: 10px;
}

a.footerlink {
  color: #999;
  font-size: 10px;
}